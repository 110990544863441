<template>
  <v-dialog v-model="productDialog" width="500" class="rounded-0" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-text-field
        @click="productPopup"
        :value="$t('Select Trigger Template')"
        class="form-input"
        append-icon="mdi-map-marker"
        outlined
        dense
        hide-details
        v-on="on"
        v-bind="attrs"
      >
       -->
      <v-text-field
        @click="productPopup"
        :placeholder="$t('Product Id or Name')"
        :value="productName"
        class="form-combobox"
        prepend-inner-icon="mdi-magnify"
        hide-details
        solo
        dense
        outlined
        v-on="on"
        v-bind="attrs"
      >
      <template v-slot:append>
        <img src="@/assets/img/ico-upload-file.gif"/>
      </template>
      </v-text-field>
    </template>
    <!-- <v-card class="popup add_store_popup">
      <h3 class="page-title-bar">
        <i class="ico ico-template"></i>{{ $t("Select Template") }}
      </h3>
      <v-card-text class="pa-0">
        <div class="form-group mb-4">

          <div class="tbl-type05" style="height: 300px;">
            <v-data-table
              :headers="templateHeaders"
              :items="templateList"
              class="gray-data-table border-table"
              height="121"
              hide-default-footer
            >
            </v-data-table>
          </div>
        </div>
      </v-card-text>
    </v-card> -->
    <v-card class="popup add_store_popup">
      <v-card-title><h3 class="page-title-bar"><i class="ico ico-template"></i>{{$t('Product Info')}}</h3></v-card-title>
      <v-card-text class="pa-0">
          <div class="tbl-type05">
            <v-data-table
              :headers="productHeaders"
              :items="productList"
              :items-per-page="100000"
              class="gray-data-table"
              hide-default-footer
              height="350"
            >
              <template v-slot:body="{ items }">
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index" @click="selectItem(item)" :class="{'selectedRow': item === selected}">
                      <td>{{(index + 1 ) + ((page - 1) * 10) }}</td>
                      <td>{{item}}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="3">
                        <p class="text-center">
                          {{ $t('No data available') }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template slot="no-data">
                  <p>
                    {{ $t('No data available') }}
                  </p>
                </template>
            </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon class="btn" @click="mapping" :style="{ width: '112px' }">{{$t('Mapping')}}</v-btn>
        <v-btn text icon @click="productDialog = false" class="btn" :style="{ width: '112px' }">{{$t('Cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
// import draggable from 'vuedraggable'

export default {
  name: 'ProductInfo',
  components: {
    // PerfectScrollbar
  },
  props: {
    productName: String
  },
  data () {
    return {
      productList: [],
      productDialog: false,
      page: 1,
      selected: '',
      productHeaders: [
        {
          text: 'NO.', value: 'number', sortable: false
        },
        {
          text: this.$t('FIELD NAME'), value: 'articleName', sortable: false
        }
      ]
    }
  },
  mounted () {
    this.getProductList()
  },
  methods: {
    selectItem (item) {
      this.selected = item
    },
    productPopup () {
      this.productDialog = true
    },
    getProductList () {
      const config = {
        params: {
          store: this.$store.state.dataStore.selectedStore.code,
          company: this.$store.state.auth.user.company
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getArticleDataKeyList.method,
          codes.requests.getArticleDataKeyList.url,
          config
        )
        .then(res => {
          // this.saveRequestConfig(res.config)
          // this.products = this.convertProductsDate(res.data.articleList)
          // this.totalProducts = this.products.length
          // this.pageInfoText = commons.getPageInfoText(res.headers)
          // this.totalPages = Number(res.headers['x-totalpages'])
          // this.selected = []
          this.productList = res.data.articleDataKeyList
        })
        .catch(error => {
          this.products = []
          this.totalProducts = this.products.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = commons.getPageInfoText(headers)
          this.totalPages = 0
          this.selected = []
          console.debug(`Could not find any products. error: ${error}`)
        })
    },
    mapping () {
      this.productDialog = false
      EventBus.$emit('mappingProductInfo', this.selected)
      EventBus.$emit('messageAlert', this.$t('SUCCESS'))
    }
  },
  computed: {}
}
</script>

<style scoped>
.selectedRow {
    background-color: #d4d4d4;
    font-weight: bold;
}
  .form-combobox .v-input__prepend-inner {
    border-right: 1px solid #808495;
    margin-right: 16px;
  }
  /* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
