<template>
  <v-dialog v-model="triggerDialog" width="500" class="rounded-0" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @click="triggerPopup"
        :value="templateName"
        class="form-input"
        append-icon="mdi-map-marker"
        outlined
        dense
        hide-details
        v-on="on"
        v-bind="attrs"
      >
      <template v-slot:append>
        <img src="@/assets/img/ico-upload-file.gif" />
      </template>
    </v-text-field>
    </template>
    <!-- <v-card class="popup add_store_popup">
      <h3 class="page-title-bar">
        <i class="ico ico-template"></i>{{ $t("Select Template") }}
      </h3>
      <v-card-text class="pa-0">
        <div class="form-group mb-4">

          <div class="tbl-type05" style="height: 300px;">
            <v-data-table
              :headers="templateHeaders"
              :items="templateList"
              class="gray-data-table border-table"
              height="121"
              hide-default-footer
            >
            </v-data-table>
          </div>
        </div>
      </v-card-text>
    </v-card> -->
    <v-card class="popup add_store_popup">
      <v-card-title> <h3 class="page-title-bar"><i class="ico ico-template"></i>{{$t('Select Template')}}</h3></v-card-title>
      <v-card-text class="pa-0">
        <div class="tbl-type05">
          <v-data-table
            items-per-page="100000"
            :headers="templateHeaders"
            :items="templateList"
            class="gray-data-table"
            hide-default-footer
            height="430"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="items.length > 0">
                <tr v-for="(item, index) in items" :key="index" @click="selectItem(item)" :class="{'selectedRow': item === selected}">
                  <td>{{(index + 1 ) + ((page - 1) * 10) }}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.m_time}}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3">
                    <p class="text-center">
                      {{ $t('No data available') }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
            <template slot="no-data">
              <p>
                {{ $t('No data available') }}
              </p>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="mappingTrigger" class="btn" :style="{ width: '112px' }">{{$t('Add')}}</v-btn>
        <v-btn text icon @click="closeTriggerDialog" class="btn" :style="{ width: '112px' }">{{$t('Cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
// import draggable from 'vuedraggable'

export default {
  components: {
    // PerfectScrollbar
  },
  props: {
    triggerTemplate: String
  },
  data () {
    return {
      templateList: [],
      templateName: this.$t('Select Trigger Template'),
      triggerDialog: false,
      page: 1,
      selected: '',
      templateHeaders: [
        {
          text: '#', value: 'number', sortable: false
        },
        {
          text: this.$t('TITLE'), value: 'title'
        },
        {
          text: this.$t('LAST MODIFIED DATE'), value: 'm_time'
        }
      ]
    }
  },
  mounted () {
    this.getTemplateList()
  },
  methods: {
    selectItem (item) {
      this.selected = item
    },
    triggerPopup () {
      this.triggerDialog = true
    },
    getTemplateList () {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('page', this.page)
      data.append('limit', 100000)
      data.append('timezone', this.$store.state.dataStore.timezone)
      data.append('type', 'list')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateList.method,
          dicamoApi.requests.getTemplateList.url,
          data
        )
        .then(res => {
          this.templateList = res.data.data

          if (this.triggerTemplate) {
            this.templateList.forEach(item => {
              if (item.idx_template === this.triggerTemplate) {
                this.templateName = item.title
              }
            })
          }
          // this.lcdlist = res.data.data
        })
        .catch(error => {
          // this.lcdlist = []
          console.debug(`Could not find any template. error: ${error}`)
        })
    },
    mappingTrigger () {
      this.templateName = this.selected.title
      EventBus.$emit('mappingTrigger', this.selected.idx_template)
      EventBus.$emit('messageAlert', this.$t('SUCCESS'))
      this.triggerDialog = false
    },
    closeTriggerDialog () {
      // templateName = 'Select Trigger Template'
      this.page = 1
      this.selected = ''
      this.triggerDialog = false
    }
  },
  computed: {}
}
</script>

<style scoped>
.selectedRow {
    background-color: #d4d4d4;
    font-weight: bold;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
