var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded-0",
      attrs: { width: "500", scrollable: "", persistent: "" },
      model: {
        value: _vm.getTemplateDialog,
        callback: function($$v) {
          _vm.getTemplateDialog = $$v
        },
        expression: "getTemplateDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-template" }),
              _vm._v(_vm._s(_vm.$t("Get Template")))
            ])
          ]),
          _c("v-card-text", { staticClass: "pa-0" }, [
            _c(
              "div",
              { staticClass: "tbl-type05" },
              [
                _c(
                  "v-data-table",
                  {
                    staticClass: "gray-data-table",
                    attrs: {
                      headers: _vm.templateHeaders,
                      items: _vm.templateList,
                      "hide-default-footer": "",
                      "items-per-page": 100000,
                      height: "430"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            items.length > 0
                              ? _c(
                                  "tbody",
                                  {
                                    staticStyle: {
                                      height: "430px",
                                      overflow: "hidden"
                                    }
                                  },
                                  _vm._l(items, function(item, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        class: {
                                          selectedRow: item === _vm.selected
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectItem(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              index + 1 + (_vm.page - 1) * 10
                                            )
                                          )
                                        ]),
                                        _c("td", [_vm._v(_vm._s(item.title))]),
                                        _c("td", [_vm._v(_vm._s(item.m_time))])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c("tbody", [
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "3" } }, [
                                      _c("p", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("No data available")
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("template", { slot: "no-data" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                      ])
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  style: { width: "112px" },
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.mappingTrigger }
                },
                [_vm._v(_vm._s(_vm.$t("Add")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  style: { width: "112px" },
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.closeGetTemplate }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }