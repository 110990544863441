var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded-0",
      attrs: { width: "500", scrollable: "", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "form-combobox",
                      attrs: {
                        placeholder: _vm.$t("Product Id or Name"),
                        value: _vm.productName,
                        "prepend-inner-icon": "mdi-magnify",
                        "hide-details": "",
                        solo: "",
                        dense: "",
                        outlined: ""
                      },
                      on: { click: _vm.productPopup },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/ico-upload-file.gif")
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.productDialog,
        callback: function($$v) {
          _vm.productDialog = $$v
        },
        expression: "productDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-template" }),
              _vm._v(_vm._s(_vm.$t("Product Info")))
            ])
          ]),
          _c("v-card-text", { staticClass: "pa-0" }, [
            _c(
              "div",
              { staticClass: "tbl-type05" },
              [
                _c(
                  "v-data-table",
                  {
                    staticClass: "gray-data-table",
                    attrs: {
                      headers: _vm.productHeaders,
                      items: _vm.productList,
                      "items-per-page": 100000,
                      "hide-default-footer": "",
                      height: "350"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            items.length > 0
                              ? _c(
                                  "tbody",
                                  _vm._l(items, function(item, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        class: {
                                          selectedRow: item === _vm.selected
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectItem(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              index + 1 + (_vm.page - 1) * 10
                                            )
                                          )
                                        ]),
                                        _c("td", [_vm._v(_vm._s(item))])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c("tbody", [
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "3" } }, [
                                      _c("p", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("No data available")
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("template", { slot: "no-data" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                      ])
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  style: { width: "112px" },
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.mapping }
                },
                [_vm._v(_vm._s(_vm.$t("Mapping")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  style: { width: "112px" },
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      _vm.productDialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }